/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Sectionblock from "./Sectionblock";

const ProjectRoll = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ProjectRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "project-post" } } }
        ) {
          edges {
            node {
              id
              html
              fields {
                slug
              }
              frontmatter {
                title
                summary
                releaseyear
                templateKey
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const { edges: posts } = allMarkdownRemark;

  return (
    <div sx={{ pt: "75px" }}>
      {posts &&
        posts.map(({ node: post }) => {
          return (
            <Sectionblock
              key={post.id}
              image={post.frontmatter.featuredimage}
              alt={post.frontmatter.title}
              color='#fafafa'
              title={post.frontmatter.title}
              text={post.frontmatter.summary}
              href={post.fields.slug}
              linktext='View Project'
            />
          );
        })}
    </div>
  );
};

export default ProjectRoll;

ProjectRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};
