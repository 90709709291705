/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../../components/Layout";
import ProjectRoll from "../../components/ProjectRoll";

const ProjectIndexPage = () => {
  return (
    <Layout>
      <ProjectRoll />
    </Layout>
  );
};

export default ProjectIndexPage;
